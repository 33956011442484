import React, { Component } from 'react'
import ConsularAdvisorScreen from './advisors/ConsularAdvisorsScreen'
import AssistantsScreen from './assistants'
import DistrictsScreen from './districts'
import SocialsNetworksScreen from './socials-networks'

import consularController from '../../controller/consularController'
import FileHelper from '../../helpers/FileHelper';
import { ENTITIES } from '../../helpers/EntityKeys';

export class ConsulScreen extends Component {

    state = {
        consuls: [],
        assistants: [],
        districts: [],
        socialNetworksTags: [],
        mainContainer: null,
        openDialog: false,
        dialogChilds: [],
        loading: true
    }

    componentDidMount() {
        this.refreshConsuls()
    }

    refreshData = e => {
        e.preventDefault()
        this.setState({loading: true}, () => this.refreshConsuls(true))
    }

    refreshConsuls = async (force = false) => {

        let assistants
        let consuls
        let districts
        let files
        let folders
        let socialNetworksTags
        let sympathizers

        if (force || !FileHelper.getLocalStorage(ENTITIES.assistants)){
            assistants = (await consularController.getAssistants()).data.assistants.map(assistant => {
                assistant = {
                    ...assistant,
                    name: assistant.firstName + ' ' + assistant.lastName
                }
                return assistant
            })
            FileHelper.updateLocalStorage(ENTITIES.assistants, assistants)
        } else assistants = FileHelper.getLocalStorage(ENTITIES.assistants)

        if (force || !FileHelper.getLocalStorage(ENTITIES.sympathizers)){
            sympathizers = (await consularController.getSympathizers()).data.sympathizers
            FileHelper.updateLocalStorage(ENTITIES.sympathizers, sympathizers)
        } else sympathizers = FileHelper.getLocalStorage(ENTITIES.sympathizers)

        if (force || !FileHelper.getLocalStorage(ENTITIES.consuls)){
            consuls = (await consularController.getConsuls()).data.consuls.concat(sympathizers).map(consul => {
                consul = { ...consul, name: consul.firstName + ' ' + consul.lastName }
                return consul
            })
             
            FileHelper.updateLocalStorage(ENTITIES.consuls, consuls)
        } else consuls = FileHelper.getLocalStorage(ENTITIES.consuls)

        if (force || !FileHelper.getLocalStorage(ENTITIES.districts)) {
            districts = (await consularController.findAllDistricts()).data.districts
            FileHelper.updateLocalStorage(ENTITIES.districts, districts)
        } else districts = FileHelper.getLocalStorage(ENTITIES.districts)
        
        if (force || !FileHelper.getLocalStorage(ENTITIES.files)) {
            files = (await consularController.getFiles()).data.files.sort((a,b)=>a.name.toLowerCase()<b.name.toLowerCase()?-1:1)
            FileHelper.updateLocalStorage(ENTITIES.files, files)
        } else files = FileHelper.getLocalStorage(ENTITIES.files)

        if (force || !FileHelper.getLocalStorage(ENTITIES.folders)) {
            folders = (await consularController.getFolders()).data.folders.filter(folder => folder.file_count > 0).sort((a,b)=>a.name.toLowerCase()<b.name.toLowerCase()?-1:1)
            FileHelper.updateLocalStorage(ENTITIES.folders, folders)
        } else folders = FileHelper.getLocalStorage(ENTITIES.folders)
        
        if (force || !FileHelper.getLocalStorage(ENTITIES.socialNetworksTags)){
            socialNetworksTags = (await consularController.getSocialNetworksTags()).data.social_networks_tags
            FileHelper.updateLocalStorage(ENTITIES.socialNetworksTags, socialNetworksTags)
        } else socialNetworksTags = FileHelper.getLocalStorage(ENTITIES.socialNetworksTags)


        let mainContainer = <ConsularAdvisorScreen lists={this.props.account.lists}
                                                   updateRootEntity={this.updateRootEntity}
                                                   consuls={consuls}
                                                   assistants={assistants}
                                                   files={files.filter(file=>file.folder_id===63)}
                                                   folders={folders}
                                                   districts={districts}
                                                   socialNetworksTags={socialNetworksTags} />

        this.setState({ mainContainer, assistants, consuls, districts, files, socialNetworksTags, sympathizers, folders }, () => this.setState({ loading: false }))
    }

    handleTab = e => {

        const id = e.target.id
        let mainContainer = this.state.mainContainer

        switch (id.toLowerCase()) {

            case 'consular':

                mainContainer = <ConsularAdvisorScreen lists={this.props.account.lists}
                                                       updateRootEntity={this.updateRootEntity}
                                                       consuls={this.state.consuls}
                                                       assistants={this.state.assistants}
                                                       folders={this.state.folders}
                                                       files={this.state.files.filter(file=>file.folder_id===63)}
                                                       districts={this.state.districts}
                                                       socialNetworksTags={this.state.socialNetworksTags} />

                break
            case 'districts':

                mainContainer = <DistrictsScreen updateRootEntity={this.updateRootEntity} districts={this.state.districts} folders={this.state.folders} files={this.state.files.filter(file=>file.folder_id===57)} socialNetworksTags={this.state.socialNetworksTags} />
                break
            case 'assistants':

                mainContainer = <AssistantsScreen updateRootEntity={this.updateRootEntity} assistants={this.state.assistants} folders={this.state.folders} files={this.state.files.filter(file=>file.folder_id===63)} />
                break
            case 'socials-networks':

                mainContainer = <SocialsNetworksScreen updateRootEntity={this.updateRootEntity} socialNetworksTags={this.state.socialNetworksTags} />
                break
            default:
                break;
        }

        this.setState({ mainContainer })
    }

    // update entity inside component since, we've made an update from child component of localStorage
    updateRootEntity = async (key, value) => {

        if(key === ENTITIES.consuls) {
            const assistants = (await consularController.getAssistants()).data.assistants.map(assistant => {
                assistant = {
                    ...assistant,
                    name: assistant.firstName + ' ' + assistant.lastName
                }
                return assistant
            })
            FileHelper.updateLocalStorage(ENTITIES.assistants, assistants)
            this.setState({ assistants, [key]: value })
            
        } else this.setState({[key]: value})
    }

    hideDialog = e => {
        this.setState({ openDialog: false })
    }



    render() {

        return !this.state.loading?(
            <div>
                <div className="uk-flex uk-flex-around uk-flex-wrap">
                    <h1>CONSULAIRES</h1>
                    <button onClick={this.refreshData} type="button" className="uk-button primary-theme-color text-white">ACTUALISER LES DONNÉES</button>
                </div>
                <div className="sizedbox-height-5"></div>
                <div className="tab-menu">
                    <ul className="uk-child-width-expand" uk-tab="media: @s">
                        <li className="uk-active"><a onClick={this.handleTab} id="consular" href="#">Têtes de liste</a></li>
                        <li><a onClick={this.handleTab} id="districts">Circonscriptions</a></li>
                        <li><a onClick={this.handleTab}  id="assistants">Co-listiers</a></li>
                        <li><a onClick={this.handleTab} id="socials-networks">Réseaux Sociaux</a></li>

                    </ul>
                </div>
                {this.state.mainContainer}
            </div>
        ):<div className='uk-position-center'><span uk-spinner="ratio: 4.5"></span></div>
    }
}

export default ConsulScreen
