import React, { Component } from 'react'
import MemberScreen from '../../members/MemberScreen'
import ListsScreen from '../../lists'
import CampaignsScreen from '../../campaigns'
import DashboardScreen from '../../dashboard'
import SettingsScreen from '../../settings'
import ConsulScreen from '../../consuls'

export class MenuItem extends Component {

    handleClick = (e) => {
        e.preventDefault()
        let screenToUpdate = null;
        switch (this.props.index) {
            case 0:
                screenToUpdate = <DashboardScreen account={this.props.account} updateListToCreate={this.props.updateListToCreate} updateScreen={this.props.container} />
                break
            case 1:
                screenToUpdate = <MemberScreen account={this.props.account} />
                break

            case 2:
                screenToUpdate = <ListsScreen account={this.props.account} updateListToCreate={this.props.updateListToCreate} updateScreen={this.props.container} />
                break
            case 3:
                screenToUpdate = <CampaignsScreen account={this.props.account} updateListToCreate={this.props.updateListToCreate} updateScreen={this.props.container} />
                break;
            case 4:
                screenToUpdate = <ConsulScreen account={this.props.account} />
                break;

            case 5:
                screenToUpdate = <SettingsScreen account={this.props.account} />
                break;
            default:
                break;
        }
        this.props.container(screenToUpdate)
    }
    render() {
        const name = this.props.name;

        return (
            <li>
                <a onClick={this.handleClick}>
                    <h4 className="uk-margin-remove">{name}</h4>
                </a>
            </li>
        )
    }
}

export default MenuItem
