import React, { Component } from 'react'
import { ListTagComponent } from './ListTagComponent'

export class FinalListValidation extends Component {


    state = {
        members: this.props.members,
        lists: this.props.account.lists,
        selectedListId: '',
        listsTagComponents: [],
        listTotal: 0,
        loading: false,
    }

    removeListInTags = (list) => {

        const listsTagComponents = this.state.listsTagComponents.filter(listTagComponent => listTagComponent.props.list.id !== list.id)
        this.props.removeList(list.id)
        this.setState({ listsTagComponents })
    }


    removeMember = (event) => {

        if (window.confirm("Sure to remove ?")) {
            const memberUuid = event.target.value

            const elements = []
            this.state.members.forEach(element => {
                if (element.uuid !== memberUuid) {
                    elements.push(element)
                }
            })
            this.props.removeItemInList(memberUuid)
            this.setState({ members: elements })
        }


    }

    onListChange = (e) => {

        const list = this.state.lists[e.target.value]
        const presents = this.state.listsTagComponents.filter(listEl => listEl.props.list.id === list.id)

        if (presents.length === 0) {
            const listTag = <ListTagComponent list={list} removeAction={this.removeListInTags} />
            const listsTagComponents = this.state.listsTagComponents
            listsTagComponents.push(listTag)
            this.props.onListChange(list.id)
            this.setState({ selectedListId: list.id, listsTagComponents })
        }


    }

    clearCart = (e) => {


        if (window.confirm("Sure to clear ?")) {
            this.setState({ members: [] })
            this.props.clearCart()
        }
    }

    render() {


        return (

            <div className="row">

                <div className='flex-column-space-around  content-30w'>
                    {this.state.listsTagComponents}
                </div>
                <div className="flex-column content-70w">

                    <div className="inline-content-center-no-space">
                        <span className="primary-theme-color-foreground" uk-icon={"icon: mail ; ratio: 2"}></span>
                        <div className="spacer-1"></div>
                        <form>
                            <div class="uk-margin">
                                <div uk-form-custom="target: > * > span:first-child">
                                    <select onChange={this.onListChange}>
                                        <option value="">Please select...</option>
                                        {this.state.lists.map((list, index) => <option value={index}>{list.name}</option>)}

                                    </select>
                                    <button class="uk-button uk-button-default" type="button" >
                                        <span></span>
                                        <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                        {this.state.loading ? <span class="uk-margin-small-right" uk-spinner="ratio: 1"></span> : null}
                    </div>



                    <div className="sizedbox-height-2"></div>

                    <div className="inline-content-center-no-space flex-right">

                        <button onClick={this.clearCart} type="submit" className="uk-button uk-button-text primary-theme-color-foreground uk-button-small">CLEAR</button>
                    </div>

                    <div className="table-container">
                        <table class="uk-table uk-table-responsive uk-table-divider">
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>EMAIL</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody className="padding-5">

                                {

                                    this.state.members.map(m => (<React.Fragment>
                                        <tr>
                                            <td>
                                                <dt><a>{m.name}</a></dt>
                                            </td>
                                            <td><div><span>{m.email_address}</span></div>
                                            </td>
                                            <td><ul className="uk-iconnav">
                                                <li><button value={m.uuid} onClick={this.removeMember} className="uk-button text-primary uk-button-small">REMOVE</button></li>
                                            </ul>
                                            </td>
                                        </tr>


                                    </React.Fragment>))
                                }


                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        )
    }
}

export default FinalListValidation
