import React, { Component } from 'react'
import countries from 'i18n-iso-countries'
import localFr from 'i18n-iso-countries/langs/fr.json'
import consularController from '../../../controller/consularController'
import DistrictMainInfos from "./DistrictMainInfos";
import SuccintComponentScreen from "../advisors/SuccintComponentScreen";
import SocialNetworksComponentScreen from "../advisors/SocialNetworksComponentScreen";
import slugify from "slugify";
import notification from '../../../services/notification';

countries.registerLocale(localFr)

export class EditDistrictForm extends Component {


    state = {
        geography: [],
        name: this.props.district.name,
        slug: this.props.district.slug,
        geographyTags: [],
        image:this.props.district.image,
        files: this.props.files,
        currentFolder: null,
        processing: false,
        continents:[
            "Afrique",
            "Asie",
            "Amérique du Nord",
            "Amérique Latine",
            "Europe",
            "Moyen Orient",
            "Océanie"
        ],
        continent: this.props.district.continent,
        city: '',
        places: [],
        message: '',
        notification: false,
        success: false,
        mainContainer:null,
        districtMainInfos:null,
        districtSuccintsScreen:null,
        districtSocialNetworksScreen:null,
        succints:this.props.district.succints.filter(succint=>succint.link.trim().length>0).map((succint=>({...succint,id:succint.uuid}))),
        socialNetworks:this.props.district.socialNetworks,
        socialNetworksTags: this.props.socialNetworksTags,
    }

    componentWillMount() {

        const geography = this.props.district.country.map(country => {


            country = {
                ...country,
                location: {
                    lat: country.latitude,
                    lng: country.longitude
                },
                properties: {
                    NAME: country.name,
                    CONTINENT: country.continent
                }
            }
            return country
        })


        const socialNetworks = this.props.district.socialNetworks.map(socialNetwork => {


            const socialNetworksTags = this.props.socialNetworksTags
            let socialTagIndex = -1

            for (let i = 0; i < socialNetworksTags.length; i++) {


                if (socialNetworksTags[i].uuid.toString() === socialNetwork.tag.uuid.toString()) {
                    socialTagIndex = i
                    break
                }
            }
            socialNetwork = {
                ...socialNetwork,
                id: socialNetwork.uuid,
                link: socialNetwork.link,
                socialTagIndex
            }

            return socialNetwork
        })

        const places = this.props.district.cities.map(place => {


            place = {
                ...place,
                label: place.name,
                location: {
                    lat: place.latitude,
                    lng: place.longitude
                }
            }

            return place
        })


        const districtMainInfos = <DistrictMainInfos image={this.state.image}
                                                     files={this.state.files}
                                                     folders={this.props.folders}
                                                     folder={this.state.currentFolder}
                                                     onFolderChange={this.onFolderChange}
                                                     name={this.state.name}
                                                     slug={this.state.slug}
                                                     updateName={this.updateName}
                                                     continent={this.state.continent}
                                                     continents={this.state.continents}
                                                     updateContinent={this.updateContinent}
                                                     updateImage={this.updateImage}
                                                     geography={geography}
                                                     geographyTags={this.state.geographyTags}
                                                     updateGeography={this.updateGeography}
                                                     places={places}
                                                     updatePlaces={this.updatePlaces} />

        const districtSuccintsScreen = <SuccintComponentScreen succints={this.state.succints} addSuccint={this.addSuccint}
                                                               updateSuccints={this.updateSuccints}/>
        const districtSocialNetworksScreen = <SocialNetworksComponentScreen socialNetworks={socialNetworks}
                                                                            socialNetworksTags={this.state.socialNetworksTags}
                                                                            addSocialNetwork={this.addSocialNetwork}
                                                                            updateSocialNetworks={this.updateSocialNetworks}/>

        this.setState({geography,places,socialNetworks,mainContainer:districtMainInfos,districtMainInfos,districtSuccintsScreen,districtSocialNetworksScreen})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.state.districtMainInfos = <DistrictMainInfos folders={this.props.folders}
                                                          folder={this.state.currentFolder}
                                                          onFolderChange={this.onFolderChange}
                                                          image={this.state.image}
                                                          files={this.props.files}
                                                          name={this.state.name}
                                                          slug={this.state.slug}
                                                          updateName={this.updateName}
                                                          continent={this.state.continent}
                                                          continents={this.state.continents}
                                                          updateContinent={this.updateContinent}
                                                          updateImage={this.updateImage}
                                                          geography={this.state.geography}
                                                          geographyTags={this.state.geographyTags}
                                                          updateGeography={this.updateGeography}
                                                          places={this.state.places}
                                                          updatePlaces={this.updatePlaces} />

        this.state.districtSuccintsScreen = <SuccintComponentScreen succints={this.state.succints} addSuccint={this.addSuccint}
                                                                    updateSuccints={this.updateSuccints}/>

        this.state.districtSocialNetworksScreen = <SocialNetworksComponentScreen socialNetworks={this.state.socialNetworks}
                                                                                 socialNetworksTags={this.state.socialNetworksTags}
                                                                                 addSocialNetwork={this.addSocialNetwork}
                                                                                 updateSocialNetworks={this.updateSocialNetworks}/>

    }

    updateName = name=>{
        this.setState({name, slug:slugify(name)})
    }

    updateImage = image =>{
        this.setState({image})
    }

    onFolderChange = (folder, images) => {
        this.setState({currentFolder: folder, files: images })
    }

    handleFieldChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    updatePlaces= places =>{
        this.setState({places})
    }

    updateGeography = geography=>{
        this.setState({geography})
    }

    updateContinent = continent=>{
        this.setState({continent})
    }
    //succints

    updateSuccints = succints => {

        this.setState({succints})
    }

    // Social Networks

    addSocialNetwork = socialNetwork => {

        const socialNetworks = this.state.socialNetworks

        socialNetworks.push(socialNetwork)
        this.setState({socialNetwork})
    }

    updateSocialNetworks = socialNetworks => {
        this.setState({socialNetworks})
    }

    updateDistrictAction = e => {

        const name = this.state.name
        const slug = slugify(this.state.name)
        const geography = this.state.geography
        const image = this.state.image
        const places = this.state.places
        const succints = this.state.succints
        const socialNetworks = this.state.socialNetworks.every(socialNetwork => socialNetwork.socialTagIndex !== -1) ? this.state.socialNetworks.map(socialNetwork => {

            const socialNetworkObj = {
                socialNetworkTagUuid: this.state.socialNetworksTags[socialNetwork.socialTagIndex].uuid,
                link: socialNetwork.link
            }

            return socialNetworkObj
        }) : []

        if(name.length <= 0){
            notification.display('Nom de la circonscription invalide 🤥', 'danger')
            return this.setState({ processing: false })
        }

        if(this.state.continent.length <= 0){
            notification.display('Sélectionne un continent 🤥', 'danger')
            return this.setState({ processing: false })
        }
        
        if(image === null || !image){
            notification.display('Sélectionne une image 🌌', 'danger')
            return this.setState({ processing: false })
        }
        
        if(geography.length <= 0){
            notification.display('Sélectionne un pays dans la liste 🤥', 'danger')
            return this.setState({ processing: false })
        }

        if(places.length <= 0){
            notification.display('Sélectionne au moins une ville 🤥', 'danger')
            return this.setState({ processing: false })
        }

        const country = this.state.geography.map(geo => {

            geo = {
                name: geo.properties.NAME,
                continent: geo.properties.CONTINENT,
                latitude: geo.latitude,
                longitude: geo.longitude
            }

            return geo
        })

        const cities = places.map(place => {

            place = {
                name: place.label,
                placeId: place.placeId,
                latitude: place.location.lat,
                longitude: place.location.lng
            }

            return place
        })

        const data = {
            districtUuid: this.props.district.uuid,
            name,
            slug,
            continent: this.state.continent,
            image,
            country,
            succints,
            socialNetworks,
            cities
        }

        this.setState({processing: true}, () => {
            consularController.updateDistrict(data).then(res =>res).then((res)=>this.props.getDistricts()).then(()=>{
                notification.display('Circonscription mise à jour...')
                this.cancelAction()
            }).catch(err=>{
                this.setState({processing: false})
                notification.display('Un problème est survenu...😢', 'danger')
            })
        })
    }
    cancelAction = e => {
        this.props.hideDialog()
    }

    handleTab = e => {

        const id = e.target.id
        let mainContainer = this.state.mainContainer

        switch (id.toLowerCase()) {

            case 'infos':
                mainContainer = this.state.districtMainInfos
                break
            case 'succints':
                mainContainer = this.state.districtSuccintsScreen
                break
            case 'socials-networks':
                mainContainer = this.state.districtSocialNetworksScreen
                break
            default:
                break;
        }

        this.setState({mainContainer})
    }

    render() {

        return (

            <div className="flex-column">

                <ul className="uk-child-width-expand" uk-tab="media: @s">
                    <li className="uk-active"><a onClick={this.handleTab} id="infos" href="#infos">Infos Principales</a></li>
                    <li><a onClick={this.handleTab} href="#succints" id="succints">Brèves</a></li>
                    <li><a onClick={this.handleTab} href="#socials-networks" id="socials-networks">Réseaux sociaux</a></li>

                </ul>

                {this.state.mainContainer}

                <div className="modal-footer inline-content-center-no-space ">
                    <button onClick={this.cancelAction} disabled={this.state.processing} type="submit" className="uk-button uk-button-large">ANNULER</button>
                    <div className="spacer-1"></div>
                    <button onClick={this.updateDistrictAction} type="submit" className="uk-button primary-theme-color text-white uk-button-large">METTRE A JOUR { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}</button>
                </div>
            </div>

        )
    }
}

export default EditDistrictForm
