import React, { Component } from 'react'
import notification from '../../../services/notification'


export class UpdateSocialNetworkTag extends Component {


    state = {
        name: this.props.socialNetworkTag.name,
        processing: false
    }


    handleFieldChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    updateSocialNetworkTagAction = e => {

        e.preventDefault()

        const socialNetworkTagName = this.state.name
        if(socialNetworkTagName.length<=0){
            return notification.display('please fill social network name...', 'danger')
        }

        this.setState({processing: true}, ()=>{
            const updatedCredentials = {
                ...this.props.socialNetworkTag,
                name: socialNetworkTagName
            }
            this.props.updateSocialNetworkTagAction(updatedCredentials).then(success => {
                if(!success) this.setState({processing: false})
            })
        })
    }

    cancelAction = e => {
        this.props.hideDialog()
    }

    render() {

        const name = this.state.name.toLowerCase()
        let icon

        if (name.includes('facebook')) icon = 'facebook'
        if (name.includes('twitter')) icon = 'twitter'
        if( name.includes('instagram')) icon = 'instagram'
        if( name.includes('linkedin')) icon = 'linkedin'
        return (
            <div className="flex-column">

                <form className="centered">
                    <div className="uk-inline content-20w">
                        <span className="uk-form-icon" uk-icon={icon} />
                        <input name="name" placeholder="social network name" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.name} />
                    </div>

                    <div className="sizedbox-height-2"></div>


                    <div className="modal-footer inline-content-center-no-space ">
                        <button onClick={this.cancelAction} type="submit" className="uk-button uk-button-large" disabled={this.state.processing}>CANCEL</button>
                        <div className="spacer-1"></div>
                        <button onClick={this.updateSocialNetworkTagAction} type="submit" className="uk-button primary-theme-color text-white uk-button-large">UPDATE { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}</button>
                    </div>

                </form>

            </div>
        )
    }
}

export default UpdateSocialNetworkTag
