import React, { Component } from 'react'
import MenuItem from './items/MenuItem'

export class DashboardMenu extends Component {

    renderContent = (screen) => {
        this.props.updateMainContainer(screen)
    }

    render() {
        return (
            <div className="dashboard-menu">
                <div>
                    <ul className="uk-tab-right" uk-tab="">
                        <MenuItem account={this.props.account} index={0} container={this.renderContent} icon="thumbnails" name="DASHBOARD" />
                        <MenuItem account={this.props.account} index={1} container={this.renderContent} icon="users" name="MEMBERS" />
                        <MenuItem account={this.props.account} updateListToCreate={this.props.updateListToCreate} index={2} container={this.renderContent} icon="file-text" name="LISTS" />
                        <MenuItem account={this.props.account} updateListToCreate={this.props.updateListToCreate} index={3} container={this.renderContent} icon="mail" name="CAMPAIGNS" />
                        <MenuItem account={this.props.account} index={4} container={this.renderContent} icon="user" name="CONSULAIRES" />
                        <MenuItem account={this.props.account} index={5} container={this.renderContent} icon="settings" name="SETTINGS" />
                    </ul>
                </div>
            </div>
        )
    }
}

export default DashboardMenu
