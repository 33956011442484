import React, { Component } from 'react'
import AddDistrictForm from './AddDistrictForm'
import { Dialog } from '../../dialog/Dialog'
import consularController from '../../../controller/consularController'
import DistrictListItem from './DistrictListItem'
import { EditDistrictForm } from './EditDistrictForm';
import FileHelper from '../../../helpers/FileHelper'
import { ENTITIES } from '../../../helpers/EntityKeys'
import notification from '../../../services/notification'


export class DistrictScreen extends Component {


    state = {
        files:this.props.files,
        folders: this.props.folders,
        districtName:'',
        tmpDistricts:this.props.districts,
        openDialog: false,
        loading: false,
        dialogChilds: [],
        districts: this.props.districts,
        dialogTitle: '',
        processing: false
    }

    handleFieldChange = e => {

        const value = e.target.value.toLowerCase()
        let districts = []

        if(value.length>0){
            districts = this.state.districts.filter(district=>district.name.toLowerCase().includes(value))
        }else districts = this.state.districts
        this.setState({ [e.target.name]: value, tmpDistricts: districts })

    }

    findAllDistricts = _ =>{
        return consularController.findAllDistricts().then(res => {

            const districts = res.data.districts
            FileHelper.updateLocalStorage(ENTITIES.districts, districts)
            this.props.updateRootEntity(ENTITIES.districts, districts)
            return this.setState({ districts, tmpDistricts: districts })
        })
    }

    refreshDistricts = e => {
        e.preventDefault()
        this.setState({loading: true}, () => this.findAllDistricts().then(() => this.setState({ loading: false})))
    }

    displayDistrictForm = e => {
        this.setState({
            openDialog: true,
            dialogTitle:'Créer circonscription',
            dialogChilds: <AddDistrictForm folders={this.state.folders} files={this.state.files} socialNetworksTags={this.props.socialNetworksTags} hideDialog={this.hideDialog} getDistricts={this.findAllDistricts} />
        })
    }

    displayDistrictEditForm = district => {
        this.setState({
            openDialog: true,
            dialogTitle:'Mettre à jour circonscription',
            dialogChilds: <EditDistrictForm folders={this.state.folders} files={this.state.files} socialNetworksTags={this.props.socialNetworksTags} district={district} hideDialog={this.hideDialog} getDistricts={this.findAllDistricts} />
        })
    }

    deleteDistrict = district => {
        consularController.deleteDistrict(district.uuid).then(res => notification.display('Circonscription supprimée...')).then(() => this.findAllDistricts()).catch(err => notification.display('Un problème est survenu lors de la suppréssion', 'danger'))
    }

    hideDialog = e => {
        this.setState({ openDialog: false })
    }

    render() {

        const districtsItems = this.state.tmpDistricts.sort((a,b)=>a.name.toLowerCase()<b.name.toLowerCase()?-1:1).map(district => <DistrictListItem key={district.uuid} district={district} displayDistrictEditForm={this.displayDistrictEditForm} deleteDistrictAction={this.deleteDistrict} />)
        return !this.state.loading?(
            <div className="flex-column">
                <div className="uk-margin uk-padding uk-flex uk-flex-row uk-flex-between">
                    <div className="inline-content-center-no-space">
                        <div className="uk-inline">
                            <span className="uk-form-icon" uk-icon="icon: search"></span>
                            <input onChange={this.handleFieldChange} name="districtName" placeholder="nom de la circonscription"
                                   className="uk-form-width-large uk-input uk-margin-remove" type="text"
                                   value={this.state.districtName}></input>
                        </div>
                    </div>
                    <div className="flex-right">
                        <button onClick={ this.displayDistrictForm } type="submit" className="uk-button primary-theme-color text-white uk-button-small">Ajouter</button>
                    </div>
                </div>
                <div className="sizedbox-height-5"></div>
                {this.state.openDialog ? (<Dialog title={this.state.dialogTitle} key='dialog' multipleSteps={true} stepContainer={this.state.dialogChilds} childs={this.state.dialogChilds} hideDialog={this.hideDialog} mainAction={this.uploadAction} />) : (<div></div>)}

                <div>
                    {
                        districtsItems
                    }
                </div>

            </div>
        ):<div className='uk-position-center'><span uk-spinner="ratio: 4.5"></span></div>
    }
}

export default DistrictScreen
