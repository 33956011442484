import React, {Component} from 'react'
import AddAssistantForm from './AddAssistantForm'
import Dialog from '../../dialog'
import consularController from '../../../controller/consularController'
import AssistantListItem from './AssistantListItem'
import {EditAssistantForm} from './EditAssistantForm'
import FileHelper from '../../../helpers/FileHelper'
import { ENTITIES } from '../../../helpers/EntityKeys'
import notification from '../../../services/notification'

export class AssistantScreen extends Component {


    state = {
        loading: false,
        files:this.props.files,
        assistants: this.props.assistants,
        tmpAssistants: this.props.assistants,
        openDialog: false,
        dialogChilds: [],
        dialogTitle:'',
        consularName:'',
        assistantName:'',
        processing: false
    }

    handleFieldChange = e => {

        const value = e.target.value.toLowerCase()

        let assistants = []

        switch(e.target.name){
            case 'consularName':
                assistants = this.state.assistants.filter(assistant=>assistant.consulName.toLowerCase().includes(value))
                if(this.state.assistantName.length>0) assistants = assistants.filter(assistant=>assistant.name.toLowerCase().includes(this.state.assistantName))
                break
            case 'assistantName':
                assistants = this.state.assistants.filter(assistant=>assistant.name.toLowerCase().includes(value))
                if(this.state.consularName.length>0) assistants = assistants.filter(assistant=>assistant.consulName.toLowerCase().includes(this.state.consularName))
                break
            default:
                break
        }
        this.setState({ [e.target.name]: value, tmpAssistants: assistants })

    }

    getAllAssistants= _=> {
        return consularController.getAssistants().then(res => {

            const assistants = res.data.assistants.map(assistant=>{

                return {
                    ...assistant,
                    name: assistant.firstName + ' ' + assistant.lastName
                }
            })
            FileHelper.updateLocalStorage(ENTITIES.assistants, assistants)
            this.props.updateRootEntity(ENTITIES.assistants, assistants)

            return this.setState({ assistants, tmpAssistants:assistants })
        })
    }

    displayAssistantForm = e => {

        if(!this.state.loading){
            this.setState({
                openDialog: true,
                dialogTitle:'Créer co-listier',
                dialogChilds: <AddAssistantForm folders={this.props.folders} files={this.state.files} hideDialog={this.hideDialog} getAssistants={this.getAllAssistants} />
            })
        }

    }

    editAssistantAction = assistant => {

        if(!this.state.loading){
            this.setState({
                openDialog: true,
                dialogTitle:'Mêtre à jour co-listier',
                dialogChilds: <EditAssistantForm folders={this.props.folders} files={this.state.files} assistant={assistant} hideDialog={this.hideDialog} updateAssistant={this.updateAssistant} getAssistants={this.getAllAssistants()} />
            })
        }
    }

    updateAssistant = assistant => {

        return consularController.updateAssistantAction(assistant).then(res => {

            if(res.status === 200){
                return this.getAllAssistants().then(()=>this.hideDialog()).then(()=> true)
            }else {
                //display error
                return false
            }
        }).catch(e => console.log(e))
    }

    deleteAssistantAction = assistant => {
        return consularController.deleteAssistant(assistant.uuid).then(res => {
            if(res.status===200){
                this.getAllAssistants().then(() => notification.display('Co-listier supprimé'))
                return true
            }else{
                notification.display('La chaîne de position de la tête de liste sera brisée, mettre d\'abord à jour la tête de liste', 'danger')
                return false
            }
        }).catch(e => {
            notification.display('La chaîne de position de la tête de liste sera brisée, mettre d\'abord à jour la tête de liste', 'danger')
            return false
        })
    }


    hideDialog = e => {
        this.setState({ openDialog: false })
    }

    render() {
        const assistants = this.state.tmpAssistants.sort((a,b)=>a.name.toLowerCase()<b.name.toLowerCase()?-1:1).map(assistant => {

            assistant = {
                ...assistant,
                name: assistant.firstName + ' ' + assistant.lastName
            }

            return <AssistantListItem key={assistant.uuid} assistant={assistant} editAssistantAction={this.editAssistantAction} deleteAssistantAction={this.deleteAssistantAction} />
        })
        return !this.state.loading ? (
            <div className="flex-column">
                <div className="uk-margin uk-padding uk-flex uk-flex-row uk-flex-between">
                    <div className="uk-width-1-3 inline-content-center-no-space">
                        <div className="uk-margin">
                            <div className="uk-inline">
                                <span className="uk-form-icon" uk-icon="icon: search"></span>
                                <input onChange={this.handleFieldChange} name="consularName" placeholder="nom tête de liste"
                                       className="uk-input uk-margin-remove" type="text"
                                       value={this.state.consularName}></input>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-3 inline-content-center-no-space">
                        <div className="uk-margin">
                            <div className="uk-inline">
                                <span className="uk-form-icon" uk-icon="icon: search"></span>
                                <input onChange={this.handleFieldChange} name="assistantName" placeholder="nom co-listier"
                                       className="uk-input uk-margin-remove" type="text"
                                       value={this.state.assistantName}></input>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-3 flex-right">
                        <button onClick={this.displayAssistantForm} type="submit" className="uk-button primary-theme-color text-white uk-button-small">Ajouter</button>
                    </div>
                </div>

                <div className="sizedbox-height-5"></div>

                {this.state.openDialog ? (<Dialog title={this.state.dialogTitle} key='dialog' multipleSteps={true} stepContainer={this.state.dialogChilds} childs={this.state.dialogChilds} hideDialog={this.hideDialog} mainAction={this.uploadAction} />) : (<div></div>)}
                <div>

                    {
                        assistants
                    }

                </div>

            </div>
        ):<div className='uk-position-center'><span uk-spinner="ratio: 4.5"></span></div>
    }
}

export default AssistantScreen
