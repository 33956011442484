import React, {Component} from 'react';
import CityTagComponent from "./CityTagComponent";
import CountryTagComponent from './CountryTagComponent'
import slugify from 'slugify'
import Geosuggest from 'react-geosuggest'
import consularController from '../../../controller/consularController';

class DistrictMainInfos extends Component {

    state = {
        geography: this.props.geography,
        name: this.props.name,
        continents:this.props.continents,
        continent:this.props.continent,
        files:this.props.files,
        image:this.props.image,
        folders: this.props.folders,
        folder: this.props.folder,
        geographyTags: this.props.geographyTags,
        city: '',
        slug:this.props.slug,
        places: this.props.places,
        processing: false
    }


    removeAction = geo => {
        const geography = this.state.geography.filter(g => g !== geo)
        console.log({before: this.state.geography, after: geography})
        this.setState({ geography }, ()=> {
            this.props.updateGeography(geography)
        })
    }

    onContinentChange = e =>{
        const index = e.target.value
        let continent = ''

        if(index>=0){
            continent = this.state.continents[e.target.value]
        }
        this.setState({continent})
        this.props.updateContinent(continent)
    }

    onFileChange = e =>{

        const fileIndex = e.target.value
        if(fileIndex.toString()!=='-1'){
            const file = {
                ...this.state.files[fileIndex],
                url:this.state.files[fileIndex].full_size_url
            }
            this.setState({image: file})
            this.props.updateImage(file)
        }
    }

    onFolderChange = e =>{
        const folderIndex = e.target.value;

        if(folderIndex.toString()!=='-1'){
            const folder = this.state.folders[folderIndex]
            this.setState({processing: true}, async () => {
                const images = (await consularController.getFilesByFolderId(folder.id, folder.created_at.toString().split('T')[0])).data.files
                this.setState({files: images, processing: false, folder}, () => {
                    this.props.onFolderChange(folder, images)
                })
            })
        }
    }

    removeCityAction = geo => {

        const places = this.state.places.filter(g => g !== geo)

        this.setState({ places })
        this.props.updatePlaces(places)

    }


    handleLocationChange = (value) => {

        if (value) {
            const places = this.state.places

            if (places.filter(p => p.placeId === value.placeId).length === 0) {

                value = {
                    ...value,
                    name: value.label
                }

                places.push(value)

                this.setState({ places })
                this.props.updatePlaces(places)
            }
        }
    }

    handleFieldChange = e => {
        this.setState({ [e.target.name]: e.target.value, slug: slugify(e.target.value) })
        this.props.updateName(e.target.value)
    }

    handleCountryChange = value =>{

        if(value){

            let geography = this.state.geography

            if(geography.filter(geo=>geo.name === value.label).length===0){

                const country = {
                    name: value.label,
                    latitude: value.location.lat,
                    longitude: value.location.lng,
                    properties:{
                        NAME: value.label
                    }
                }
                geography = [...geography,country]
                this.setState({geography}, ()=> {
                    this.props.updateGeography(geography)
                })
            }
        }
    }

    render() {

        const geographyTags = this.state.geography.map(geo => <CountryTagComponent geo={geo} removeAction={this.removeAction} />)
        const citiesTags = this.state.places.map(place => <CityTagComponent place={place} removeAction={this.removeCityAction} />)
        const folderSelected = this.state.folder !== null
        return (
            <div className="row">
                <div className="flex-column content-70w">
                    <div>
                        <h3><u>Pays</u></h3>

                        {geographyTags}
                    </div>
                    <div className="sizedbox-height-2">

                    </div>
                    <div>
                        <h3><u>Villes</u></h3>
                        {
                            citiesTags
                        }
                    </div>
                </div>


                <div className="flex-column content-30w ">
                    <h3>Slug : {this.state.slug}</h3>
                    <div className="uk-inline">
                        <span className="uk-form-icon" uk-icon="home" />
                        <input name="name" placeholder="nom de la circonscription" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.name} />
                    </div>

                    <div className="inline-content-center-no-space">
                        <span className="primary-theme-color-foreground" uk-icon={"icon: world ; ratio: 1.5"}></span>
                        <div className="spacer-1"></div>
                        <form>
                            <div className="uk-margin">
                                <div uk-form-custom="target: > * > span:first-child">
                                    <select onChange={this.onContinentChange}>
                                        <option value={-1}>continent...</option>
                                        {this.state.continents.map((continent, index) => <option value={index} selected={continent===this.state.continent}>{continent}</option>)}

                                    </select>
                                    <button className="uk-button uk-button-default" type="button">
                                        <span></span>
                                        <span className="primary-theme-color-foreground"
                                              uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="inline-content-center-no-space">
                        <span className="primary-theme-color-foreground" uk-icon={"icon: folder ; ratio: 2"}></span>
                        <div className="spacer-1"></div>
                        <form>
                            <div className="uk-margin">
                                <div uk-form-custom="target: > * > span:first-child">
                                    <select onChange={this.onFolderChange}>
                                        <option value={-1}>dossiers...</option>
                                        {this.state.folders.map((folder, index) => <option value={index} selected={folderSelected?folder.id.toString()===this.state.folder.id.toString():false}>{folder.name}</option>)}
                                    </select>
                                    <button className="uk-button uk-button-default" type="button">
                                        <span></span>
                                        <span className="primary-theme-color-foreground"
                                              uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="inline-content-center-no-space">
                        <span className="primary-theme-color-foreground" uk-icon={"icon: image ; ratio: 1.5"}></span>
                        <div className="spacer-1"></div>
                        <form>
                            <div className="uk-margin">
                                <div uk-form-custom="target: > * > span:first-child">
                                    <select onChange={this.onFileChange} disabled={this.state.processing}>
                                        <option value={-1}>images...</option>
                                        {this.state.files.map((file, index) => <option value={index} selected={this.state.image!==null?file.id.toString()===this.state.image.id.toString():false}>{file.name}</option>)}

                                    </select>
                                    <button className="uk-button uk-button-default" type="button">
                                        <span></span>
                                        <span className="primary-theme-color-foreground"
                                              uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                        { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null }
                    </div>

                    <div className="uk-inline">
                        <Geosuggest
                            name="district"
                            placeholder="rechercher le pays"
                            inputClassName="uk-align-center uk-input bottom-border no-border"
                            onSuggestSelect={this.handleCountryChange}
                            suggestsClassName="suggestion-input"
                        />
                    </div>

                    <div className="uk-inline">
                        <Geosuggest
                            name="city"
                            placeholder="rechercher une ville"
                            inputClassName="uk-align-center uk-input bottom-border no-border"
                            onSuggestSelect={this.handleLocationChange}
                            suggestsClassName="suggestion-input"
                        />

                    </div>
                </div>
            </div>
        );
    }
}

export default DistrictMainInfos;