import React, { Component } from 'react'
import consularController from '../../../controller/consularController'
import notification from '../../../services/notification'

export class EditAssistantForm extends Component {

    state = {
        files:this.props.files,
        folders: this.props.folders,
        firstName: this.props.assistant.firstName,
        lastName: this.props.assistant.lastName,
        externalLink:this.props.assistant.externalLink,
        biography: this.props.assistant.biography,
        image: this.props.assistant.image,
        position:this.props.assistant.position,
        positions:[],
        processing: false,
        processingFiles: false
    }

    componentWillMount() {
        const positions = []

        for(let i=1;i<28;i++) positions.push(i)

        this.setState({positions})
    }

    onPositionChange= e =>{

        const index = e.target.value

        if(index.toString() !== '-1'){
            this.setState({position:this.state.positions[index]})
        }
    }

    onFolderChange = e =>{
        const folderIndex = e.target.value;

        if(folderIndex.toString()!=='-1'){
            const folder = this.state.folders[folderIndex]
            this.setState({processFiles: true}, async () => {
                const images = (await consularController.getFilesByFolderId(folder.id, folder.created_at.toString().split('T')[0])).data.files
                this.setState({files: images, processFiles: false, folder})
            })
        }
    }

    onFileChange = e =>{

        const fileIndex = e.target.value
        if(fileIndex!==-1 && this.state.files[fileIndex]){
            const file = {
                ...this.state.files[fileIndex],
                url:this.state.files[fileIndex].full_size_url
            }
            this.setState({image: file})
        }else this.setState({image: this.props.assistant.image})
    }
    handleFieldChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    editAssistantAction = e => {
        e.preventDefault()

        const position = this.state.position
        const assistantUuid = this.props.assistant.uuid
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const biography = this.state.biography
        const externalLink = this.state.externalLink
        let image = this.state.image
        const regExp = RegExp('https://www.facebook.[fr|com]/*')


        if(position<=0 || position >= 28){
            notification.display('Saisis une position valide 🤥', 'danger')
            return
        }

        if(firstName.length <= 0){
            notification.display('Saisis le prénom 🤥', 'danger')
            return
        }

        if(lastName.length <= 0){
            notification.display('Saisis le nom 🤥', 'danger')
            return
        }

        if(externalLink !== null &&externalLink.length>0 && !regExp.test(externalLink.toLowerCase())){
            notification.display('Entre un lien facebook valide ! 🤥', 'danger')
            return
        }

        if(biography.trim().length <= 0){
            notification.display('Saisis une biographie 🤥', 'danger')
            return
        }

        if(biography.length > 1024){
            notification.display('La biographie ne peut pas avoir plus de 1024 caractères 🤥', 'danger')
            return
        }

        if(!image){
            notification.display('Sélectionne une image 🌌', 'danger')
            return
        }


        image = {
            id:image.id,
            url:image.url
        }
        const assistant = {
            position,
            assistantUuid,
            firstName,
            lastName,
            externalLink,
            biography,
            image
        }

        this.setState({ processing: true }, () => {
            this.props.updateAssistant(assistant).then(succes=>{
                if(!succes){
                    notification.display('La chaîne des positions des co-listiers est brisée chez la tête de liste concernée', 'danger')
                    this.setState({processing: false})
                } else {
                    notification.display('Co-listier mis à jour 😁')
                }
            })
        })

    }

    cancelAction = e => {
        this.props.hideDialog()
    }
    render() {
        const bioSize = this.state.biography.length
        return (
            <div className="flex-column">
                <form >
                    <div className="centered ">
                        <div className="flex-row-center">
                            <div>
                                <img className="circular" src={this.state.image?.url} width="100" height="100"
                                     alt="consul-image"/>
                            </div>
                            <div className="sizedbox-width-5"/>
                            <div className="flex-column">

                                <div className="inline-content-center-no-space">
                                    <span className="primary-theme-color-foreground" uk-icon={"icon: hashtag ; ratio: 1.5"}></span>
                                    <div className="spacer-1"></div>
                                    <form>
                                        <div className="uk-margin">
                                            <div uk-form-custom="target: > * > span:first-child">
                                                <select onChange={this.onPositionChange}>
                                                    <option value={-1}>position...</option>
                                                    {this.state.positions.map((position, index) => <option value={index} selected={position.toString()===this.state.position.toString()}>{position}</option>)}

                                                </select>
                                                <button className="uk-button uk-button-default" type="button">
                                                    <span></span>
                                                    <span className="primary-theme-color-foreground"
                                                          uk-icon="icon: chevron-down"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="uk-inline content-20w">
                                    <span className="uk-form-icon" uk-icon="icon: user" />
                                    <input name="firstName" placeholder="prénom" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.firstName} />
                                </div>
                                <div className="uk-inline content-20w">
                                    <span className="uk-form-icon" uk-icon="icon: user" />
                                    <input name="lastName" placeholder="nom" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.lastName} />
                                </div>

                                <div className="uk-inline content-20w">
                                    <span className="uk-form-icon" uk-icon="icon: facebook" />
                                    <input name="externalLink" placeholder="facebook" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.externalLink} />
                                </div>
                            </div>
                        </div>

                        <div className="inline-content-center-no-space">
                            <span className="primary-theme-color-foreground" uk-icon={"icon: folder ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div className="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onFolderChange} disabled={this.state.processFiles}>
                                            <option value={-1}>dossiers...</option>
                                            {this.state.folders.map((folder, index) => <option value={index}>{folder.name}</option>)}
                                        </select>
                                        <button className="uk-button uk-button-default" type="button">
                                            <span></span>
                                            <span className="primary-theme-color-foreground"
                                                uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="inline-content-center-no-space">
                            <span className="primary-theme-color-foreground" uk-icon={"icon: image ; ratio: 1.5"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div className="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onFileChange}>
                                            <option value={-1}>images...</option>
                                            {this.state.files.map((file, index) => <option value={index} selected={file.id.toString()===this.state.image.id.toString()}>{file.name}</option>)}

                                        </select>
                                        <button className="uk-button uk-button-default" type="button">
                                            <span></span>
                                            <span className="primary-theme-color-foreground"
                                                  uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            { this.state.processFiles ? <div className="uk-margin-small-left" uk-spinner=""/>: null }
                        </div>
                        <div className="uk-inline content-20w">
                            <span class={`uk-label uk-label-${bioSize<=1024 && bioSize>0? 'success' :'danger'}`}>{bioSize<=1024 && bioSize>0 ? 'OK':'Error'}</span><span>({`${bioSize} character${bioSize>0?'s':''}`})[max: 1024]</span>
                            <textarea className="uk-textarea" rows="5" cols="70" name="biography" placeholder="biography" onChange={this.handleFieldChange} type="text" value={this.state.biography} ></textarea>
                        </div>
                        <div className="sizedbox-height-5"></div>

                        <div className="modal-footer inline-content-center-no-space ">
                            <button onClick={this.cancelAction} type="submit" className="uk-button uk-button-large" disabled={this.state.processing}>Annuler</button>
                            <div className="spacer-1"></div>
                            <button onClick={this.editAssistantAction} type="submit" className="uk-button primary-theme-color text-white uk-button-large">Mettre à jour { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default EditAssistantForm
