import React, { Component } from 'react'

export class SocialNetworkListItem extends Component {

    state = {
        processing: false
    }
    deleteSocialNetworkAction = e => {
        if (window.confirm('Sure to delete this tag ?')) {
            this.setState({processing: true}, () => {
                this.props.deleteSocialNetworkTag(this.props.socialNetworkTag).then(success => {
                    if(!success) this.setState({processing: false})
                })
            })
        }
    }

    updateSocialNetworkTag = e => {
        this.props.updateSocialNetworkTag(this.props.socialNetworkTag)
    }

    render() {

        const socialNetworkTag = this.props.socialNetworkTag
        const name = socialNetworkTag.name.toLowerCase()
        let icon

        if (name.includes('facebook')) icon = 'facebook'
        if (name.includes('twitter')) icon = 'twitter'
        if( name.includes('instagram')) icon = 'instagram'
        if( name.includes('linkedin')) icon = 'linkedin'

        return (
            <header className="uk-comment-header uk-grid-medium uk-flex-middle" uk-grid>
                <div className="uk-width-expand">
                    <div className="flex-row centered">
                        <div className="flex-row centered">
                            <span className="primary-theme-color-foreground" uk-icon={`icon: ${icon} ; ratio: 1.5`}></span>
                            <div className="spacer-1"></div>
                            <div className="flex-column">
                                <div className="sizedbox-height-2"></div>
                                <a onClick={this.updateSocialNetworkTag} className="uk-link-reset" >{socialNetworkTag.name.toUpperCase()}</a>
                                <ul class="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
                                </ul>
                            </div>
                        </div>
                        {!this.state.processing ? <ul className="uk-iconnav">
                            <li><a onClick={this.updateSocialNetworkTag} className="primary-theme-color" href="#" uk-icon="icon: pencil; ratio: 1.5"></a></li>
                            <li><a onClick={this.deleteSocialNetworkAction} className="primary-theme-color" href="#" uk-icon="icon: close; ratio: 1.5"></a></li>
                        </ul>: <button onClick={this.updateDistrictAction} type="submit" className="uk-button primary-theme-color text-white uk-button-large">Suppression... { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}</button>}
                    </div>
                </div>
                <div className="default-divider "></div>
            </header>
        )
    }
}

export default SocialNetworkListItem
