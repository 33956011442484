import React, { Component } from 'react'
import consularController from '../../../controller/consularController'

export class SuccintComponentScreen extends Component {

    state = {

        succints: []
    }

    componentWillMount() {

        const succints = this.props.succints
        this.setState({ succints })
    }

    addSuccint = e => {

        const key = '_' + Math.random().toString(36).substr(2, 9)
        let succintObject = {
            id: key,
            link: '',
            title:'',
            description:'',
            image:''
        }


        const succints = this.state.succints
        succints.push(succintObject)

        this.setState({ succints })

    }

    updateSuccint = succintObject => {


        let succintsObjects = this.state.succints

        succintsObjects.forEach((succint, index) => {

            if (succintObject.id === succint.id) {
                succintsObjects[index] = succintObject
            }
        })

        this.setState({ succints: succintsObjects })
        this.props.updateSuccints(succintsObjects)

    }

    removeSuccint = succint => {

        const succints = this.state.succints.filter(succintObj => succintObj.id.toString() !== succint.id.toString())
        this.setState({ succints })
        this.props.updateSuccints(succints)


    }
    render() {

        const succints = this.state.succints.map(succint => <SuccintComponent object={succint} removeSuccint={this.removeSuccint} updateSuccint={this.updateSuccint} />)
        return (
            <div className='flex-column'>

                <div className="flex-right">
                    <button onClick={this.addSuccint} type="submit" className="uk-button primary-theme-color text-white">ADD</button>
                </div>

                <div className="sizedbox-height-5"></div>

                {succints.map((succint) => succint)}

            </div>
        )
    }
}

export default SuccintComponentScreen

class SuccintComponent extends Component {


    state = {
        link: this.props.object.link,
        image:this.props.object.image,
        title:this.props.object.title,
        description:this.props.object.description
    }

    componentWillMount() {

        const link = this.props.object.link
        this.setState({ link })
    }

    handleChange = e => {


        const id = this.props.object.id
        const link = e.target.value
        this.setState({ link })

        if(this.isValidURL(link)){


            consularController.getMetaFromUrl(link).then(res=>{

                const image  = res.data.image
                const description = res.data.description
                const title = res.data.title

                if(image && description && title){

                    const succint = {
                        id,
                        link,
                        image,
                        title,
                        description
                    }

                    this.setState({image,description,title})
                    this.props.updateSuccint(succint)

                }else{
                    this.setState({image:'',description:'',title:''})
                }

            }).catch(err=>err)
        }else{
                console.log('ko...')

            this.setState({image:'',description:'',title:''})
        }

    }

     isValidURL= str=> {
        const a  = document.createElement('a')
        a.href = str
        return (a.host && a.host != window.location.host)
    }

    removeSuccint = e => {

        this.props.removeSuccint(this.props.object)
    }

    render() {
        const link = this.state.link
        return (


            <div className="flex-row centered">
                <div className="uk-width-auto">
                    <img className="uk-comment-avatar circular" src={this.state.image} width="60" height="60"
                         alt="succint-image"/>
                </div>
                <div className="flex-column">
                    <h4>{this.state.title}</h4>
                    <div className="uk-margin">
                        <textarea onChange={this.handleChange} className="uk-textarea" rows="2" cols="40"
                                  placeholder="succint" value={link}></textarea>
                    </div>

                </div>
                <div>
                    <a onClick={this.removeSuccint} className="primary-theme-color" href="#" uk-icon="icon: close; ratio: 1.5"></a>
                </div>
            </div>

        )
    }
}
