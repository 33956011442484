import React, { Component } from 'react'
import AddSocialNetworkTag from './AddSocialNetworkTag'
import SocialNetworkListItem from './SocialNetworkListItem'
import { Dialog } from '../../dialog/Dialog'
import consularController from '../../../controller/consularController'
import { UpdateSocialNetworkTag } from './UpdateSocialNetworkTag'
import notification from '../../../services/notification'
import FileHelper from '../../../helpers/FileHelper'
import { ENTITIES } from '../../../helpers/EntityKeys';
export class SocialNetworksScreen extends Component {



    state = {
        socialNetworksTags: this.props.socialNetworksTags,
        openDialog: false,
        dialogChilds: [],
        dialogTitle:'',
    }

    findAllSocialNetworksTags = () => {
        return consularController.getSocialNetworksTags().then(res => {
            const socialNetworksTags = res.data.social_networks_tags
            FileHelper.updateLocalStorage(ENTITIES.socialNetworksTags, socialNetworksTags)
            this.props.updateRootEntity(ENTITIES.socialNetworksTags, socialNetworksTags)
            return this.setState({ socialNetworksTags })
        })
    }

    displaySocialNetworkForm = e => {
        this.setState({
            openDialog: true,
            dialogTitle:'Créer le tag du réseau social',
            dialogChilds: <AddSocialNetworkTag createSocialNetworkTag={this.createSocialNetworkTag} hideDialog={this.hideDialog} />
        })
    }

    createSocialNetworkTag = socialNetworkTagName => {
        const data = {
            name: socialNetworkTagName
        }
        return consularController.createSocialNetworkTag(data).then(res => res.status === 200).then((success) => {
            this.findAllSocialNetworksTags()
            return success
        }).then((success) => {
            this.hideDialog()
            return success
        }).catch(err =>{
            notification.display('Something wrong happened', 'danger')
            return false
        })
    }

    deleteSocialNetworkTag = socialNetworkTag => {
        return consularController.deleteSocialNetworkTag(socialNetworkTag.uuid).then(res => res.status === 200).then((success) => {
            
            if(success){
                this.findAllSocialNetworksTags()
                notification.display('Social network tag deleted...')
                return true
            }
        
            return false
        }).catch(err => notification.display('Something wrong happened', 'danger'))
    }

    updateSocialNetworkTag = socialNetworkTag => {

        this.setState({
            openDialog: true,
            dialogTitle:'UPDATE SOCIAL NETWORK TAG',
            dialogChilds: <UpdateSocialNetworkTag socialNetworkTag={socialNetworkTag} hideDialog={this.hideDialog} updateSocialNetworkTagAction={this.updateSocialNetworkTagAction} />
        })
    }

    updateSocialNetworkTagAction = socialNetworkTag => {

        const data = {
            socialNetworkTagUuid: socialNetworkTag.uuid,
            socialNetworkTagName: socialNetworkTag.name
        }
        return consularController.updateSocialNetworkTag(data).then(res => res.status === 200).then((success) => {
            
            if(success){
                this.findAllSocialNetworksTags()
                return true
            }
            return false
        }).then((success) => {

            if(success){
                notification.display('social network tag updated...')
                this.hideDialog()
                return true
            }
            return false
        }).catch(err => {
            notification.display('Something wrong happened', 'danger')
            return false
        })
    }

    hideDialog = e => {
        this.setState({ openDialog: false })
    }

    render() {

        const socialNetworksTags = this.state.socialNetworksTags.map(tag => <SocialNetworkListItem key={tag.uuid} socialNetworkTag={tag} deleteSocialNetworkTag={this.deleteSocialNetworkTag} updateSocialNetworkTag={this.updateSocialNetworkTag} />)
        return (
            <div className="flex-column">

                {this.state.openDialog ? (<Dialog title={this.state.dialogTitle} key='dialog' multipleSteps={true} stepContainer={this.state.dialogChilds} childs={this.state.dialogChilds} hideDialog={this.hideDialog} mainAction={this.uploadAction} />) : (<div></div>)}

                <div className="flex-right">
                    <button onClick={this.displaySocialNetworkForm} type="submit" className="uk-button primary-theme-color text-white uk-button-small">Ajouter</button>
                </div>

                <div className="sizedbox-height-5"></div>

                {
                    socialNetworksTags
                }

            </div>
        )
    }
}

export default SocialNetworksScreen
