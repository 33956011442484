import React, { Component } from 'react'
import logo from '../../../assets/images/logo.png'
export class DistrictListItem extends Component {

    state = {
        processing: false
    }
    editDistrictAction = e => {

        this.props.displayDistrictEditForm(this.props.district)
    }

    deleteDistrictAction = e => {

        if (window.confirm('Are you sure to delete? It\'s irreversible !')) {
            this.setState({ processing: true }, () => {
                this.props.deleteDistrictAction(this.props.district)
            })
        }

    }
    render() {

        const district = this.props.district

        let citiesHoverName = ''

        district.cities.forEach(element => {

            citiesHoverName += element.name + ' | '
        });
        return (
            <header class="uk-comment-header uk-grid-medium uk-flex-middle" uk-grid>
                <div class="uk-width-expand">
                    <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-small-top">
                        <div className="uk-flex">
                            <div class="uk-width-auto">
                                <img class="uk-comment-avatar" src={district.image ? district.image.url : logo} width="100" height="130" alt="district-thumbnail" />
                            </div>
                            <div className="uk-margin-left flex-column">
                                <a onClick={this.editDistrictAction} class="uk-link-reset" >{district.name}</a>
                                <ul class="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
                                    {district.continent ? <li><a href="#" className="uk-icon-link uk-margin-small-right" uk-icon="world"></a>{district.continent.toUpperCase()}</li> : null}
                                    <li><a href="#" className="uk-icon-link uk-margin-small-right" uk-icon="users"></a>{district.consulsUuid.length+district.sympathizersUuid.length}</li>
                                    <li><a href="#" className="uk-icon-link uk-margin-small-right" uk-icon="location" uk-tooltip={citiesHoverName}></a>{district.cities.length}</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            { !this.state.processing ? <ul className="uk-iconnav">
                                <li><a onClick={this.editDistrictAction} className="primary-theme-color" href="#" uk-icon="icon: pencil; ratio: 1.5"></a></li>
                                <li><a onClick={this.deleteDistrictAction} className="primary-theme-color" href="#" uk-icon="icon: close; ratio: 1.5"></a></li>
                            </ul>: <button onClick={this.updateDistrictAction} type="submit" className="uk-button primary-theme-color text-white uk-button-large">Suppression... { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}</button> }
                        </div>
                    </div>
                </div>
                <div className="default-divider "></div>
            </header>
        )
    }
}

export default DistrictListItem
