import React, { Component } from 'react'
import countries from 'i18n-iso-countries'
import localFr from 'i18n-iso-countries/langs/fr.json'
import consularController from '../../../controller/consularController'
import DistrictMainInfos from "./DistrictMainInfos"
import SuccintComponentScreen from "../advisors/SuccintComponentScreen"
import SocialNetworksComponentScreen from "../advisors/SocialNetworksComponentScreen"
import slugify from "slugify"
import notification from '../../../services/notification'

countries.registerLocale(localFr)

export class AddDistrictForm extends Component {


    state = {
        mainContainer:null,
        districtMainInfos:null,
        districtSuccintsScreen:null,
        districtSocialNetworksScreen:null,
        geography: [],
        name: '',
        continents:[
            "Afrique",
            "Asie",
            "Amérique du Nord",
            "Amérique Latine",
            "Europe",
            "Moyen Orient",
            "Océanie"
        ],
        continent:'',
        image:null,
        currentFolder: null,
        geographyTags: [],
        files: this.props.files,
        places: [],
        succints:[],
        socialNetworks:[],
        socialNetworksTags: this.props.socialNetworksTags,
        message: '',
        notification: false,
        success: false,
        processing: false
    }

    componentDidMount() {

        const districtMainInfos = <DistrictMainInfos image={this.state.image}
                                                     folders={this.props.folders}
                                                     folder={this.state.currentFolder}
                                                     onFolderChange={this.onFolderChange}
                                                     files={this.state.files}
                                                     name={this.state.name}
                                                     updateName={this.updateName}
                                                     continent={this.state.continent}
                                                     continents={this.state.continents}
                                                     updateContinent={this.updateContinent}
                                                     updateImage={this.updateImage}
                                                     geography={this.state.geography}
                                                     geographyTags={this.state.geographyTags}
                                                     updateGeography={this.updateGeography}
                                                     places={this.state.places}
                                                     updatePlaces={this.updatePlaces} />

        const districtSuccintsScreen = <SuccintComponentScreen succints={this.state.succints} addSuccint={this.addSuccint}
                                                       updateSuccints={this.updateSuccints}/>
        const districtSocialNetworksScreen = <SocialNetworksComponentScreen socialNetworks={this.state.socialNetworks}
                                                                            socialNetworksTags={this.state.socialNetworksTags}
                                                                            addSocialNetwork={this.addSocialNetwork}
                                                                            updateSocialNetworks={this.updateSocialNetworks}/>

        this.setState({mainContainer:districtMainInfos,districtMainInfos,districtSuccintsScreen,districtSocialNetworksScreen})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.state.districtMainInfos = <DistrictMainInfos folders={this.props.folders}
                                                          folder={this.state.currentFolder}
                                                          onFolderChange={this.onFolderChange}
                                                          image={this.state.image}
                                                          files={this.state.files}
                                                          name={this.state.name}
                                                          updateName={this.updateName}
                                                          continent={this.state.continent}
                                                          continents={this.state.continents}
                                                          updateContinent={this.updateContinent}
                                                          updateImage={this.updateImage}
                                                          geography={this.state.geography}
                                                          geographyTags={this.state.geographyTags}
                                                          updateGeography={this.updateGeography}
                                                          places={this.state.places}
                                                          updatePlaces={this.updatePlaces} />

        this.state.districtSuccintsScreen = <SuccintComponentScreen succints={this.state.succints} addSuccint={this.addSuccint}
                                                                    updateSuccints={this.updateSuccints}/>

        this.state.districtSocialNetworksScreen = <SocialNetworksComponentScreen socialNetworks={this.state.socialNetworks}
                                                                                 socialNetworksTags={this.state.socialNetworksTags}
                                                                                 addSocialNetwork={this.addSocialNetwork}
                                                                                 updateSocialNetworks={this.updateSocialNetworks}/>
    }

    updateName = name=>{
        this.setState({name})
    }

    onFolderChange = (folder, images) => {
        this.setState({currentFolder: folder, files: images })
    }

    handleFieldChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }


    updatePlaces= places =>{
        this.setState({places})
    }

    updateGeography = geography=>{
        this.setState({geography})
    }

    updateContinent = continent=>{
        this.setState({continent})
    }

    updateImage = image =>{
        this.setState({image})
    }

    // Succints

    addSuccint = succint => {

        const succints = this.state.succints
        succints.push(succint)
        this.setState({succints})

    }

    updateSuccints = succints => {

        this.setState({succints})
    }

    // Social Networks

    addSocialNetwork = socialNetwork => {

        const socialNetworks = this.state.socialNetworks

        socialNetworks.push(socialNetwork)
        this.setState({socialNetwork})
    }

    // Succints

    addSuccint = succint => {

        const succints = this.state.succints
        succints.push(succint)
        this.setState({succints})

    }

    updateSuccints = succints => {

        this.setState({succints})
    }

    updateSocialNetworks = socialNetworks => {
        this.setState({socialNetworks})
    }

    createDistrictAction = async e => {

        this.setState({processing: true}, () => {
            const name = this.state.name
            const slug = slugify(this.state.name)
            const geography = this.state.geography
            const image = this.state.image
            const places = this.state.places
            const succints = this.state.succints
            const socialNetworks = this.state.socialNetworks.every(socialNetwork => socialNetwork.socialTagIndex !== -1) ? this.state.socialNetworks.map(socialNetwork => {

                const socialNetworkObj = {
                    socialNetworkTagUuid: this.state.socialNetworksTags[socialNetwork.socialTagIndex].uuid,
                    link: socialNetwork.link
                }

                return socialNetworkObj
            }) : []

            if(name.length <= 0){
                notification.display('Nom de la circonscription invalide 🤥', 'danger')
                return this.setState({ processing: false })
            }

            
            if(this.state.continent.length <= 0){
                notification.display('Sélectionne un continent 🤥', 'danger')
                return this.setState({ processing: false })
            }
            
            if(image === null || !image){
                notification.display('Sélectionne une image 🌌', 'danger')
                return this.setState({ processing: false })
            }
            
            if(geography.length <= 0){
                notification.display('Sélectionne un pays dans la liste 🤥', 'danger')
                return this.setState({ processing: false })
            }

            if(places.length <= 0){
                notification.display('Sélectionne au moins une ville 🤥', 'danger')
                return this.setState({ processing: false })
            }
            const country = this.state.geography

            const cities = places.map(place => {

                place = {
                    name: place.label,
                    countryName: place.countryName,
                    placeId: place.placeId,
                    latitude: place.location.lat,
                    longitude: place.location.lng
                }

                return place
            })


            const data = {
                name,
                slug,
                continent: this.state.continent,
                image,
                country,
                succints,
                socialNetworks,
                cities
            }

            this.setState({ processing: true }, () => consularController.createDistrict(data).then(res => res).then(() => this.props.getDistricts()).then(() => {
                notification.display('Circonscription créée 😁')
                this.cancelAction()
            }).catch(err => {
                notification.display('Un problème est survenu...😢')
                this.setState({processing: false})
            }))
                
        })
    
    }


    cancelAction = e => {
        this.props.hideDialog()
    }

    handleTab = e => {

        const id = e.target.id
        let mainContainer = this.state.mainContainer

        switch (id.toLowerCase()) {

            case 'infos':
                mainContainer = this.state.districtMainInfos
                break
            case 'succints':
                mainContainer = this.state.districtSuccintsScreen
                break
            case 'socials-networks':
                mainContainer = this.state.districtSocialNetworksScreen
                break
            default:
                break;
        }

        this.setState({mainContainer})
    }
    render() {

        return (

            <div className="flex-column">

                <ul className="uk-child-width-expand" uk-tab="media: @s">
                    <li className="uk-active"><a onClick={this.handleTab} id="infos" href="#infos">Infos Principales</a></li>
                    <li><a onClick={this.handleTab} href="#succints" id="succints">Brèves</a></li>
                    <li><a onClick={this.handleTab} href="#socials-networks" id="socials-networks">Réseaux sociaux</a></li>

                </ul>
                {this.state.mainContainer}

                <div className="modal-footer inline-content-center-no-space ">
                    <button onClick={this.cancelAction} type="submit" className="uk-button uk-button-large" disabled={this.state.processing}>Annuler</button>
                    <div className="spacer-1"></div>
                    <button onClick={this.createDistrictAction} type="submit" className="uk-button primary-theme-color text-white uk-button-large">Créer { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}</button>
                </div>
            </div>

        )
    }
}

export default AddDistrictForm
