import React, { Component } from 'react'

export class ContentScreen extends Component {

    render() {
        return (
            this.props.screen
        )
    }
}

export default ContentScreen
