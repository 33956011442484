import React, { Component } from 'react'

export class AssistantListItem extends Component {

    state = {
        processing: false
    }
    editAssistantAction = e => {
        e.preventDefault()
        this.props.editAssistantAction(this.props.assistant)
    }

    deleteAssistantAction = e => {
        e.preventDefault()

        if (window.confirm("Are you sure to delete this assistant ? it's irreversible")) {
            this.setState({ processing: true }, () => {
                this.props.deleteAssistantAction(this.props.assistant).then(res => {
                    if(!res){
                        this.setState({processing: false})
                    }
                })
            })
        }
    }
    render() {

        const assistant = this.props.assistant
        const consulName = assistant.consulName.length>0?assistant.consulName:'assign me a consular advisor'
        return (
            <header class="uk-comment-header uk-grid-medium uk-flex-middle" uk-grid>

                <div class="uk-width-expand ">

                    <div className="flex-row centered">

                        <div className="flex-row centered">

                            <div className="uk-width-auto">
                                <img className="uk-comment-avatar " src={assistant.image.url} width="60" height="60"
                                     alt="assistant-image"/>
                            </div>

                            <div className="spacer-2 "></div>

                            <div className="flex-column">
                                <div className="sizedbox-height-2"></div>
                                <a onClick={this.editAssistantAction} className="uk-link-reset" ><b>{ assistant.name.toUpperCase() + ' #'+assistant.position}</b></a>
                                <ul className="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
                                     <li><a href="#" className="uk-icon-link uk-margin-small-right" uk-icon="user"></a>{consulName.toUpperCase()}</li>
                                </ul>

                            </div>
                        </div>
                        {
                            !this.state.processing ? 
                            <ul className="uk-iconnav">
                                <li><a onClick={this.editAssistantAction} className="primary-theme-color" href="#" uk-icon="icon: pencil; ratio: 1.5"></a></li>
                                <li><a onClick={this.deleteAssistantAction} className="primary-theme-color" href="#" uk-icon="icon: close; ratio: 1.5"></a></li>
                            </ul>
                            :<button onClick={this.updateDistrictAction} type="submit" className="uk-button primary-theme-color text-white uk-button-large">Suppression... { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}</button>
                        }
                    </div>


                </div>
                <div className="default-divider "></div>
                <div className="sizedbox-height-2"/>
            </header>
        )
    }
}

export default AssistantListItem
