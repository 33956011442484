import React, { Component } from 'react'
import AppBarHeader from './AppBarHeader'
import DashboardMenu from './DashboardMenu'
import ContentScreen from './content/ContentScreen'
import Dashboard from '../dashboard'
import Dialog from '../dialog'
import FinalListValidation from '../lists/FinalListValidation'
import userController from '../../controller/userController'
import listController from '../../controller/listsController'
export class HomeScreen extends Component {

    state = {
        username: '',
        contentIndex: 0,
        screen: (<div></div>),
        openFinalList: false,
        listToCreate: new Set(),
        dialogChilds: [],
        accountInfos: {},
        finalListId: '',
        finalListsId: [],
        logout: false,
        loading: true,
        isResponsive: false,
        isOnMobile: false
    }

    listKey = 'finalList'
    accountInfosKey = 'accountInfos'

    componentWillMount() {
        this.setState({loading: true}, ()=> {
            userController.validateToken().then(res => {
                if(!res.data) window.location.href = '/'
                else {
                    if (!localStorage.getItem(this.listKey)) {
                        localStorage.setItem(this.listKey, JSON.stringify([]))
                    }
            
                    if (!localStorage.getItem(this.accountInfosKey)) {
                        this.setState({loading: true})
                        userController.getAccountInfo().then(res => {
                            const username = window.localStorage.getItem('username')
                            localStorage.setItem(this.accountInfosKey, JSON.stringify(res.data))
                            this.setState({
                                accountInfos: res.data,
                                username,
                                screen: <Dashboard account={res.data} updateScreen={this.updateMainContainer} />,
                                loading: false
                            })
                        })
                    } else {
            
                        const accountInfos = JSON.parse(localStorage.getItem(this.accountInfosKey))
                        const username = window.localStorage.getItem('username')
                        this.setState({
                            accountInfos,
                            username,
                            loading: false,
                            screen: <Dashboard account={accountInfos} updateScreen={this.updateMainContainer} />,
                        })
                    }
                }
            })
        })
    }

    componentDidMount() {
        const isResponsive = window.innerWidth <= 1265
        const isOnMobile = window.innerWidth <= 640

        this.setState({ isResponsive, isOnMobile, loading: false }, () => window.addEventListener('resize', this.trackWindowSize))
    }

    trackWindowSize = event => {
        const isResponsive = event.currentTarget.window.innerWidth <= 1265
        const isOnMobile = event.currentTarget.window.innerWidth <= 640
        this.setState({ isResponsive, isOnMobile })
    }

    getAllMergeFieldsValidation = async lists => {

        const badLists = []

        for (let i = 0; i < lists.length; i++) {

            const list = await listController.getListMergeFields(lists[i].id).then(res => res.data.merge_fields)

            if (list.length !== 8) {
                badLists.push(list)
            }
        }
    }


    updateMainContainer = (screen) => {

        this.setState({
            screen
        })
    }

    updateCredentials = (e) => {

        this.setState({ loading: true })
        userController.getAccountInfo().then(res => {
            const username = window.localStorage.getItem('username')
            localStorage.setItem(this.accountInfosKey, JSON.stringify(res.data))
            this.setState({
                loading: false,
                accountInfos: res.data,
                screen: <Dashboard account={res.data} updateScreen={this.updateMainContainer} />,
                username
            })
        })
    }

    displayTmpList = (e) => {
        e.preventDefault()
        let finalListElements = this.getFinalList()

        this.setState({
            openFinalList: true,
            dialogChilds: <FinalListValidation account={this.state.accountInfos} onListChange={this.updateFinalId} validateFinalList={this.validateFinalList} removeItemInList={this.removeItemInList} members={finalListElements} removeList={this.removeItemInFinalList} clearCart={this.clearCart} />
        })

    }


    updateListToCreate = (tmpList) => {

        let tests = JSON.parse(localStorage.getItem(this.listKey))

        tmpList.forEach((element) => {

            if (!this.elementIsPresentIn(tests, element)) {
                tests.push(element)
            }

        })

        let serializedList = JSON.stringify(tests);
        localStorage.setItem(this.listKey, serializedList)


    }

    clearCart = () => {
        this.setNewFinalList([])
    }

    elementIsPresentIn = (array, element) => {

        let isPresent = false;
        array.forEach(el => {
            if (el.uuid === element.uuid) {
                isPresent = true;
            }
        })
        return isPresent;
    }

    hideDialog = () => {
        this.setState({
            openFinalList: false
        })
    }

    getFinalList = () => {
        return JSON.parse(localStorage.getItem(this.listKey))
    }

    updateFinalId = (listId) => {

        const finalListsId = this.state.finalListsId
        finalListsId.push(listId)
        this.setState({ finalListsId })
    }

    validateFinalList = () => {
        const list = localStorage.getItem(this.listKey)


        this.state.finalListsId.forEach(listId => {
            listController.addMembersInList(listId, list).then(res => {
                console.log(res.data)
            })
        })

    }

    removeItemInFinalList = (listId) => {
        const finalListsId = this.state.finalListsId.filter(listElId => listElId === listId)
        this.setState({ finalListsId })
    }

    removeItemInList = (memberUuid) => {

        let list = this.getFinalList()

        for (let i = 0; i < list.length; i++) {
            if (list[i].uuid === memberUuid) {
                list.splice(i, 1)
            }
        }
        this.setNewFinalList(list)

    }

    setNewFinalList = (list) => {
        localStorage.setItem(this.listKey, JSON.stringify(list))
    }

    logout = () => {
        window.localStorage.clear()
        window.location.href = '/'
    }

    render() {
        const isResponsive = this.state.isResponsive
        return !this.state.loading ?  (<div className="main-container">
                {isResponsive ?
                    <div>
                        <a id="menu-btn" className="primary-theme-color" uk-icon="icon: menu; ratio: 1.5" uk-toggle="target: #offcanvas-nav"></a>
                            <div id="offcanvas-nav" uk-offcanvas="overlay: true">
                                <div class="uk-offcanvas-bar">
                                    <DashboardMenu account={this.state.accountInfos} updateListToCreate={this.updateListToCreate} updateMainContainer={this.updateMainContainer} /> 
                                </div>
                            </div>
                    </div> : null}
                    <AppBarHeader username={this.state.username} logout={this.logout} />

                    <div className="uk-flex uk-flex-center uk-flex-start uk-margin-top">
                        {!isResponsive ? <div className="uk-width-1-6@m uk-margin-large-top">
                            <DashboardMenu account={this.state.accountInfos} updateListToCreate={this.updateListToCreate} updateMainContainer={this.updateMainContainer} />
                        </div>: null}
                        <div className="uk-margin-right uk-width-5-6@m">
                            <ContentScreen screen={this.state.screen} />
                        </div>

                        {this.state.openFinalList ? (<Dialog key='dialog' childs={this.state.dialogChilds} hideDialog={this.hideDialog} mainAction={this.validateFinalList} />) : (<div></div>)}
                        <div id="tmp-list-btn-container">
                            <a onClick={this.updateCredentials} id="refresh-account-btn" className="uk-icon-button primary-theme-color" uk-icon="refresh"></a>
                        </div>
                        {/* <div id="tmp-list-btn-container">
                            <a onClick={this.displayTmpList} id="tmp-list-btn" className="uk-icon-button primary-theme-color" uk-icon="file-text"></a>
                        </div> */}
                    </div>
                </div>
        ): (<div className="full-screen"><span className="uk-margin-small-right" uk-spinner="ratio: 3"></span></div>)
    }
}

export default HomeScreen
