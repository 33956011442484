import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css'
import SignIn from './layouts/signin'
import NotFound from './layouts/404'
import Home from './layouts/home'
import * as routes from './utils/routes'

function App() {

  return <Router>
    <Switch>
      <Route exact path={routes.SIGN_IN_ROUTE} component={SignIn} />
      <Route exact path={routes.HOME_ROUTE} component={Home} />
      <Route path={routes.NOT_FOUND_ROUTE} component={NotFound} />
      <Redirect to={routes.NOT_FOUND_ROUTE} />
    </Switch>

  </Router>
}

export default App;
