import React, { Component } from 'react'

export class AddSocialNetworkTag extends Component {


    state = {
        name: '',
        icon: 'rss',
        processing: false
    }


    handleFieldChange = e => {
        this.setState({ [e.target.name]: e.target.value, icon: e.target.value })

        if (e.target.value.length === 0) this.setState({ icon: 'rss' })
    }

    createSocialNetworkTagAction = e => {

        e.preventDefault()
        const socialNetworkTagName = this.state.name

        if (socialNetworkTagName.length > 0) {
            this.setState({processing: true}, ()=> {
                this.props.createSocialNetworkTag(socialNetworkTagName)
            })
        }
    }

    cancelAction = e => {
        this.props.hideDialog()
    }

    render() {

        const name = this.state.icon.toLowerCase()
        let icon

        if (name.includes('facebook')) icon = 'facebook'
        if (name.includes('twitter')) icon = 'twitter'
        if( name.includes('instagram')) icon = 'instagram'
        if( name.includes('linkedin')) icon = 'linkedin'
        return (
            <div className="flex-column">

                <form className="centered">
                    <div className="uk-inline content-20w">
                        <span className="uk-form-icon" uk-icon={icon} />
                        <input name="name" placeholder="nom du réseau social" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.name} />
                    </div>

                    <div className="sizedbox-height-2"></div>


                    <div className="modal-footer inline-content-center-no-space ">
                        <button onClick={this.cancelAction} type="submit" className="uk-button uk-button-large" disabled={this.state.processing}>ANNULER</button>
                        <div className="spacer-1"></div>
                        <button onClick={this.createSocialNetworkTagAction} type="submit" className="uk-button primary-theme-color text-white uk-button-large">CRÉER { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}</button>
                    </div>

                </form>

            </div>
        )
    }
}

export default AddSocialNetworkTag
